import { Provider } from 'react-redux'

import { PersistWrapper } from '../redux/persist'
import reduxStore from '../redux/store'
import ReduxInit from '../relay/ReduxInit'

const { store, persistor } = reduxStore

const ReduxProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Provider store={store}>
      <PersistWrapper persistor={persistor}>
        <ReduxInit />
        {children}
      </PersistWrapper>
    </Provider>
  )
}

export default ReduxProvider
