import React, { FC } from 'react'
import { PersistGate } from 'redux-persist/integration/react'

export const PersistWrapper: FC<{
  children: React.ReactNode
  persistor: any
}> = ({ children, persistor }) => (
  <PersistGate persistor={persistor} loading={children}>
    {children}
  </PersistGate>
)
